var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "aside" }, [
      _c("div", { staticClass: "title" }, [_vm._v("营销活动")]),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/marketingActivity/OwnActivity", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/OwnWorkbench/bar-4.png")
                    }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("我的活动")])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/marketingActivity/CollectedTemp", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/OwnWorkbench/bar-5.png")
                    }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("我的模板")])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/marketingActivity/PrizeResources", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/OwnWorkbench/bar-5.png")
                    }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("奖品资源")])
              ])
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "main" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }